import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Toast from 'react-bootstrap/Toast';
import './css/login.css'
import { useState, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { authAction } from './store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_BACKEND_URL } from './dev_variables';
import { ToastContainer, toast } from 'react-toastify';
import '../node_modules/react-toastify/dist/ReactToastify.css';

export default function Login() {
    const [ email, setEmail ] = useState('alexobidiegwu@gmail.com');
    const [ password, setPassword ] = useState('alex');
    const [isLoginValid, setIsLoginValid] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    

    const changeEmail = (event) => {
        setEmail(event.target.value)
    }

    const changePassword = (event) => {
        setPassword(event.target.value)
    }
    function sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const loginAdmin = async(event) => {
        event.preventDefault();
        if (!email || !password) {
            toast.error("Invalid Password or email")
            return
        }
        setIsLoading(true)
        const req = await fetch(REACT_APP_BACKEND_URL+'/login', {
            method:'POST',
            body:JSON.stringify({'email':email, 'password':password}),
            headers:{
                'Content-Type':'application/json'
            }
        })
        const {status, result, token} = await req.json()
        if (!status){
            setIsLoading(false)
            toast.error(result)
            return
        }
        if (status && result._id) {
            // update redux state
            toast.success("You are being redirected!")
            await sleep(1500);
            var id = result._id
            localStorage.removeItem('token')
            localStorage.removeItem('token_expiration')
            localStorage.setItem('token', token)
            const expiration = new Date()
            expiration.setHours(expiration.getHours() + 1)
            localStorage.setItem('token_expiration', expiration.toISOString())
            dispatch(authAction.login({ id:id, email:email, username:result.username }))
            // return redirect('/dashboard')
        }
    }

  return (
    <Container style={{"height":"100%"}} className='d-flex align-items-center justify-content-center'>
        <Form method='GET' className='login-bg'>
            <div className='my-5'>
                <b><h2>Hey, Gonzalo 👋</h2></b>
            </div>
            <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control 
                    name="email" 
                    className='main-input'
                    type="email"
                    placeholder="Enter email" 
                    value={email}
                    onChange={changeEmail}
                    required 
                />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                    name="password" 
                    className='main-input'
                    type="password" 
                    placeholder="Password"
                    value={password}
                    onChange={changePassword}
                    required
                />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>
            <div className="d-grid gap-2 mt-5">
                <Button disabled={isLoading} onClick={loginAdmin} className='btn main-button' type="submit" size="lg">
                    <span>Submit</span>
                </Button>
            </div>
        </Form>
        <ToastContainer />
    </Container>
  );
}
